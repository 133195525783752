import Vue from 'vue/dist/vue.esm'

document.addEventListener('DOMContentLoaded', () => {



    const app = new Vue({

        el: '#shopApp',
        data: {
            mainnav: false,
            order: {
                org: false,
                gift: false,
                terms: false,
                privacy: false
            }
        },
        mounted() {
          const urlParams = new URLSearchParams(window.location.search)
          const gift = urlParams.get('gift')
          console.log(gift)
          if (gift == "true") {
            this.order.gift = true
          }
        },
        methods: {
          updateGiftcardPrice: function(line_id) {
            var product_price = document.getElementById("product_price_" + line_id).value;
            var url = "/cart?f=update&line_id=" + line_id + "&f=update&product_price=" + product_price;
            window.location = url 
          }        
        }
    })
});
